<template>
	<ej-page>
		<ej-mobile-header>
			<ej-toolbar>
				<ej-buttons>
					<ej-back-button></ej-back-button>
				</ej-buttons>

				<ej-title>{{ $route.meta.title }}</ej-title>
			</ej-toolbar>
		</ej-mobile-header>

		<ej-content>
			<ej-breadcrumb>
				<li class="breadcrumb-item">
					<router-link :to="{name: 'people'}">Pessoas</router-link>
				</li>
				<li class="breadcrumb-item">
					{{ $route.meta.title }}
				</li>
			</ej-breadcrumb>

			<main class="content">
				<div class="container-fluid" v-if="spinner">
					<div class="row d-flex justify-content-center">
						<div class="default-spinner">
							<div class="default-spinner-inner">
								<div></div>
								<div></div>
							</div>
						</div>
					</div>
				</div>

				<div class="container-fluid" v-else>
                    <photo-swipe
                        ref="pswp"
                        :items="person.signature"
                        :options="{
                            closeOnScroll: false
                        }"
                    ></photo-swipe>

					<div class="row">
						<div class="col">
							<h5>Informações da Pessoa</h5>
						</div>
					</div>

					<div class="row">
						<div class="form-group col-lg-1 col-12">
							<label>Id</label>
							<input
								type="text"
								v-model.trim="person.id"
								class="form-control"
								disabled="disabled"
							/>
						</div>

						<div class="form-group col-lg-2 col-12">
							<label>Código</label>
							<input
								type="text"
								v-model.trim="person.code"
								class="form-control"
							/>
						</div>

						<div class="col-lg-9 col-12 d-flex flex-wrap">
							<div class="form-group text-center mr-3">
								<label>Tipo Pessoa*</label><br>
								<ej-toggle-button
									v-model.trim="personTypeChecked"
									:color="{checked: '#75c791', unchecked: '#75c791'}"
									:labels="{checked: 'PF', unchecked: 'PJ'}"
								/>
							</div>

							<div class="form-group text-center mr-3">
								<label>Tipo Cadastro* <font-awesome-icon v-tooltip="'C - Cadastro completo | S - Cadastro simples'" :icon="['far', 'question-circle']"></font-awesome-icon></label><br>
								<ej-toggle-button
									v-model.trim="regTypeChecked"
									:color="{checked: '#75c791', unchecked: '#75c791'}"
									:labels="{checked: 'Completo', unchecked: 'Simples'}"
									:width="110"
								/>
							</div>

							<div class="form-group text-center mr-3">
								<label>É cliente?*</label><br>
								<ej-toggle-button
									v-model.trim="person.customer"
									:labels="{checked: 'Sim', unchecked: 'Não'}"
                                    :disabled="true"
								/>
							</div>

							<div class="form-group text-center mr-3">
								<label>É revenda?*</label><br>
								<ej-toggle-button
									v-model.trim="person.reseller"
									:labels="{checked: 'Sim', unchecked: 'Não'}"
									:disabled="person.reg_type !== 'full'"
								/>
							</div>

							<div class="form-group text-center mr-3">
								<label>
									É usuário?*
									<font-awesome-icon v-tooltip="'Se esta pessoa será um usuário do sistema'" :icon="['far', 'question-circle']"></font-awesome-icon>
								</label><br>
								<ej-toggle-button
									v-model.trim="person.user"
									:labels="{checked: 'Sim', unchecked: 'Não'}"
									:disabled="!canSelectUser"
								/>
							</div>

							<div class="form-group text-center mr-3">
								<label>
									É emitente?*
									<font-awesome-icon v-tooltip="'Se esta pessoa poderá emitir pedidos'" :icon="['far', 'question-circle']"></font-awesome-icon>
								</label><br>
								<ej-toggle-button
									v-model.trim="person.seller"
									:labels="{checked: 'Sim', unchecked: 'Não'}"
                                    :disabled="!canSelectUser"
								/>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="form-group col-xl-3 col-lg-5 col-12">
							<label v-if="personTypeChecked">Nome Completo*</label>
							<label v-else>Nome Fantasia*</label>
							<input
								type="text"
								v-model.trim="person.name"
								class="form-control"
								v-uppercase
							/>
						</div>

						<div v-if="!personTypeChecked" class="form-group col-lg-5 col-12">
							<label>Razão Social<ej-asterisk v-if="person.reg_type === 'full'"></ej-asterisk></label>
							<input
								type="text"
								v-model.trim="person.business_name"
								class="form-control"
								v-uppercase
							/>
						</div>

						<div class="form-group col-xl-2 col-lg-3 col-12">
							<label v-if="personTypeChecked">
								CPF<ej-asterisk v-if="person.reg_type === 'full'"></ej-asterisk>
							</label>
							<label v-else>
								CNPJ<ej-asterisk v-if="person.reg_type === 'full'"></ej-asterisk>
							</label>
							<the-mask
								v-if="personTypeChecked"
								type="text"
								v-model.trim="person.document"
								mask="###.###.###-##"
								:masked="false"
								class="form-control"
							/>
							<the-mask
								v-else
								type="text"
								v-model.trim="person.document"
								mask="##.###.###/####-##"
								:masked="false"
								class="form-control"
							/>
						</div>

						<div class="form-group col-xl-2 col-lg-3 col-12">
							<label>
								IE <font-awesome-icon v-tooltip="'Se não possuir, ou seja, for isento, informar ISENTO.'" :icon="['far', 'question-circle']"></font-awesome-icon>
							</label>
							<input
								type="text"
								v-model.trim="person.ie"
								class="form-control"
							/>
						</div>

						<div class="form-group col-xl-3 col-lg-4 col-12">
							<label>
								E-mail<ej-asterisk v-if="person.reg_type === 'full'"></ej-asterisk> <font-awesome-icon v-tooltip="'Obrigatório quando for cadastro completo <strong>e</strong>, revendedor ou usuário'" :icon="['far', 'question-circle']"></font-awesome-icon>
							</label>
							<input
								type="email"
								v-model.trim="person.email"
								class="form-control"
								:disabled="person.user && !canSelectUser"
								v-lowercase
							/>
						</div>

						<div v-if="person.user" class="form-group col-lg-2 col-12">
							<label>Login*</label>
							<input
								type="email"
								v-model.trim="person.username"
								class="form-control"
								:disabled="!canSelectUser"
								v-lowercase
							/>
						</div>

						<div class="form-group col-lg-3 col-12">
							<label>Cidade<ej-asterisk v-if="person.reg_type === 'full'"></ej-asterisk></label>
							<ej-select
								v-model.trim="city"
								:options="cities"
								track-by="id"
								:custom-label="cityCustomLabel"
								placeholder="Buscar"
								@search-change="cityAsyncFind"
								:loading="cityFinding"
							></ej-select>
						</div>

						<div class="form-group col-lg-3 col-12">
							<label>Endereço<ej-asterisk v-if="person.reg_type === 'full'"></ej-asterisk></label>
							<input
								type="text"
								v-model.trim="person.address"
								class="form-control"
								v-uppercase
							/>
						</div>

						<div class="form-group col-xl-2 col-lg-3 col-12">
							<label>Bairro<ej-asterisk v-if="person.reg_type === 'full'"></ej-asterisk></label>
							<input
								type="text"
								v-model.trim="person.neighborhood"
								class="form-control"
								v-uppercase
							/>
						</div>

						<div class="form-group col-lg-2 col-12">
							<label>CEP<ej-asterisk v-if="person.reg_type === 'full'"></ej-asterisk></label>
							<the-mask
								type="text"
								v-model.trim="person.cep"
								class="form-control"
								mask="#####-###"
								:masked="false"
							/>
						</div>

                        <div class="form-group col-xl-2 col-lg-3 col-12" v-if="person.seller">
                            <label>O que pode vender? <ej-asterisk></ej-asterisk></label>
                            <ej-select
                                v-model.trim="person.allowed_types"
                                :options="allowed_types"
                                track-by="value"
                                label="name"
                                :multiple="true"
                            ></ej-select>
                        </div>

						<div class="form-group col-lg-3 col-12">
							<label>Ponto de referência</label>
							<input
								type="text"
								v-model.trim="person.reference_point"
								class="form-control"
								v-uppercase
							/>
						</div>

                        <div
                            v-if="
								!person.reseller
								&& (
									$store.state.auth.permissions['people.createAny']
									|| $store.state.auth.permissions['people.updateAny']
								)"
                            class="form-group col-xl-3 col-lg-4 col-12"
                        >
                            <label>Vend./Prom./Repr.* <font-awesome-icon v-tooltip="'Vendedor/Promotor/Representante a qual essa pessoa &quot;pertence&quot; (conforme região por exemplo)'" :icon="['far', 'question-circle']"></font-awesome-icon></label>
                            <ej-select
                                v-model.trim="seller"
                                :options="sellers"
                                track-by="id"
                                label="name"
                                placeholder="Buscar"
                                @search-change="sellerAsyncFind"
                                :loading="sellerFinding"
                            ></ej-select>
                        </div>

                        <div class="form-group col-12" v-if="person.seller">
                            <label>Regiões permitidas<ej-asterisk></ej-asterisk></label>
                            <ej-select
                                v-model.trim="person.regions"
                                :options="states"
                                track-by="id"
                                placeholder="Buscar"
                                label="name"
                                :loading="regionsFinding"
                                :close-on-select="false"
                                :multiple="true"
                            ></ej-select>
                        </div>
					</div>

                    <div class="row">
                        <div class="col-xl-3 col-lg-6 mt-3">
                            <div>
                                <h5>Endereço de cobrança <font-awesome-icon :icon="['far', 'question-circle']"></font-awesome-icon></h5>
                                <textarea rows="5" class="form-control" v-model.trim="personMetaModel['billing_address']"></textarea>
                            </div>
                        </div>

                        <div class="col-xl-3 col-lg-6 mt-3">
                            <div>
                                <h5>Informações de alerta <font-awesome-icon v-tooltip="'Usado para sinalizar pessoas que já deram problema'" :icon="['far', 'question-circle']"></font-awesome-icon></h5>
                                <textarea rows="5" class="form-control" v-model.trim="person.warning"></textarea>
                            </div>
                        </div>

                        <div class="col-xl-3 col-lg-6 mt-3">
                            <div>
                                <h5>Telefones* <font-awesome-icon v-tooltip="'Obrigatório pelo menos um número telefone'" :icon="['far', 'question-circle']"></font-awesome-icon></h5>
                                <table class="desktop-list-content">
                                    <thead>
                                        <tr>
                                            <th>Número</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr :key="index" v-for="(item, index) in person.phones">
                                            <td>
                                                <the-mask
                                                    v-model.trim="person.phones[index]"
                                                    type="text"
                                                    class="form-control"
                                                    :mask="[
													'(##) ####-####',
													'(##) #####-####'
												]"
                                                    :masked="true"
                                                />
                                            </td>
                                            <td>
                                                <ej-button class="btn btn-primary" @click="removePhone(index)">
                                                    <font-awesome-icon :icon="['far', 'trash-alt']"></font-awesome-icon>
                                                </ej-button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div class="mobile-list-content">
                                    <div class="row">
                                        <div class="col-12 col-md-3" :key="index" v-for="(item, index) in person.phones">
                                            <div class="card">
                                                <div class="card-body d-flex align-items-center">
                                                    <h5 class="card-title mb-0 mr-2">
                                                        <the-mask
                                                            v-model.trim="person.phones[index]"
                                                            type="text"
                                                            class="form-control"
                                                            :mask="[
													'(##) ####-####',
													'(##) #####-####'
												]"
                                                            :masked="true"
                                                        />
                                                    </h5>

                                                    <div class="actions m-0">
                                                        <button class="btn btn-primary m-0" @click="removePhone(index)">
                                                            <font-awesome-icon :icon="['far', 'trash-alt']"></font-awesome-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="text-center">
                                <ej-button class="btn btn-secondary hvr-pulse" @click="addPhone">
                                    <font-awesome-icon :icon="['fas', 'plus']"></font-awesome-icon> Número
                                </ej-button>
                            </div>
                        </div>

                        <div v-if="person.user" class="col-xl-3 col-lg-6 mt-3">
                            <div>
                                <h5>Grupos de permissão<span v-if="person.user">*</span></h5>
                                <table class="desktop-list-content">
                                    <thead>
                                        <tr>
                                            <th>Grupo</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr :key="index" v-for="(item, index) in rolesBrute">
                                            <td>
                                                <ej-select
                                                    v-model.trim="rolesBrute[index]"
                                                    :options="roles"
                                                    track-by="id"
                                                    label="name"
                                                    placeholder="Buscar"
                                                    :disabled="!canSelectUser"
                                                ></ej-select>
                                            </td>
                                            <td>
                                                <ej-button :disabled="!canSelectUser" class="btn btn-primary" @click="removeRole(index)">
                                                    <font-awesome-icon :icon="['far', 'trash-alt']"></font-awesome-icon>
                                                </ej-button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div class="mobile-list-content">
                                    <div class="row">
                                        <div class="col-12 col-md-3" :key="index" v-for="(item, index) in rolesBrute">
                                            <div class="card">
                                                <div class="card-body d-flex align-items-center">
                                                    <h5 class="card-title mb-0 mr-2">
                                                        <ej-select
                                                            v-model.trim="rolesBrute"
                                                            :options="roles"
                                                            track-by="id"
                                                            label="name"
                                                            placeholder="Buscar"
                                                            :disabled="!canSelectUser"
                                                        ></ej-select>
                                                    </h5>

                                                    <div class="actions m-0">
                                                        <button :disabled="!canSelectUser" class="btn btn-primary m-0" @click="removeRole(index)">
                                                            <font-awesome-icon :icon="['far', 'trash-alt']"></font-awesome-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="text-center">
                                <ej-button :disabled="!canSelectUser" class="btn btn-secondary hvr-pulse" @click="addRole">
                                    <font-awesome-icon :icon="['fas', 'plus']"></font-awesome-icon> Grupo
                                </ej-button>
                            </div>
                        </div>
                    </div>

                    <template v-if="person.seller">
                        <div class="row">
                            <div class="col">
                                <h5 style="margin-top: 10px">Assinatura a ser usada nos pedidos*</h5>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xl-3 col-lg-4 col-md-6 col-12">
                                <ej-dropzone
                                    v-model="person.signature"
                                    :multiple="false"
                                    :create-thumbnail="false"
                                    image-type="image/png"
                                    :quality="100"
                                    :resize="false"
                                ></ej-dropzone>
                            </div>

                            <div
                                class="col-xl-3 col-lg-4 col-md-6 col-12 bloco-foto"
                                :key="index"
                                v-for="(foto, index) in person.signature"
                            >
                                <div
                                    class="foto"
                                    :style="{backgroundImage: `url(\'${foto.src}\')`}"
                                >
                                    <div class="overlay">
                                        <button @click="openPhoto(index)">
                                            <font-awesome-icon size="3x" :icon="['fas', 'search-plus']"></font-awesome-icon>
                                        </button>

                                        <button class="excluir" @click="excluiFoto(index)">
                                            <font-awesome-icon size="2x" :icon="['fas', 'times-circle']"></font-awesome-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

					<br>

					<div class="row d-flex justify-content-center">
						<ej-button
							@click="salvar"
							class="btn btn-secondary hvr-pulse"
							:disabled="saving"
						>
							<strong>Salvar  <font-awesome-icon :icon="['fas', 'cog']" spin v-if="saving"></font-awesome-icon></strong>
						</ej-button>
					</div>
				</div>
			</main>
		</ej-content>
	</ej-page>
</template>

<script>
	//Mixins
	import FetchingMixin from "@/mixins/FetchingMixin";
	import Swal from "sweetalert2";
	import {exibeErro, sanitize} from "@/helpers";

	//Aux
	import EjButton from "@/components/EjButton";
	import EjAsterisk from "@/components/EjAsterisk";
    import {vueSet} from "vue-deepset";
    import EjDropzone from "@/components/EjDropzone";
    import PhotoSwipe from "@/components/PhotoSwipe";
	const _ = require('lodash');

	export default {
		name: 'PersonEdit',
		components: {
            EjAsterisk,
            EjButton,
            EjDropzone,
            PhotoSwipe
        },

		data() {
			return {
				loadingPerson: false,
				loadingSellers: false,
				loadingRoles: false,
				cityFinding: false,
                regionsFinding: false,
				sellerFinding: false,
				saving: false,
				operation: undefined,

				sellers: [],
				cities: [],
				states: [],
				roles: [],
                allowed_types: [
                    {
                        name: "Cabinas",
                        value: "cabin",
                    },
                    {
                        name: "Outros",
                        value: "other",
                    },
                ],
				rolesBrute: [],

                /* Criei o meta fora do order por causa do problema de reatividade do Vue,
				no final eu incluo ele pra salvar */
                personMeta: {
                    billingAddress: undefined
                },

				person: {
					name: undefined,
					business_name: undefined,
					document: undefined,
                    signature: [],
                    customer: true,
					ie: undefined,
					type: 'physical',
					reg_type: 'full',
					email: undefined,
                    allowed_types: [],
					address: undefined,
					reference_point: undefined,
					neighborhood: undefined,
					cep: undefined,
                    warning: undefined,
					phones: [],
					city_id: undefined,
                    regions: [],
					seller_id: this.$store.state.auth.user.id, //Ele mesmo como padrão
					reseller: undefined,
					seller: undefined,
					user: undefined,
					roles: undefined
				},
			}
		},

		watch: {
			"person.seller"(newValue) {
				/* Se a pessoa estiver sinalizada como vendedora, então não define seller_id. Porque se não fica
					uma relação "vendedor do vendedor". */
				if (newValue)
					this.person.seller_id = null;
				else {
                    this.person.regions = [];

                    if (this.$store.state.auth.permissions['people.createOwn'] && !this.$store.state.auth.permissions['people.createAny'])
                        this.person.seller_id = this.$store.state.auth.user.id;
                }
			},

			"person.reg_type"(newValue) {
				if (newValue === 'simple')
                    this.person.user = false;
			}
		},

		computed: {
            personMetaModel() {
                return this.$deepModel(this.personMeta);
            },

			spinner() {
				return this.loadingPerson || this.loadingSellers || this.loadingRoles || this.regionsFinding;
			},

			canSelectUser() {
				return this.person.reg_type === "full" &&
					(
						this.$store.state.auth.permissions['users.createAny']
						|| this.$store.state.auth.permissions['users.updateAny']
						|| this.$store.state.auth.permissions['users.deleteAny']
					);
			},

			personAnyPermission() {
				return this.$store.state.auth.permissions['people.createAny']
				|| this.$store.state.auth.permissions['people.updateAny']
				|| this.$store.state.auth.permissions['people.deleteAny']
			},

			personTypeChecked: {
				get() {
					const type = _.get(this.person, 'type');
					return type === 'physical';
				},

				set(value) {
					this.person.type = value ? 'physical' : 'legal';
				}
			},

			regTypeChecked: {
				get() {
					const reg_type = _.get(this.person, 'reg_type');
					return reg_type === 'full';
				},

				set(value) {
					this.person.reg_type = value ? 'full' : 'simple';
				}
			},

			seller: {
				get() {
					const seller_id = _.get(this.person, 'seller_id');
					return seller_id ? this.sellers.find((s) => s.id == seller_id) : null;
				},

				set(seller) {
					if (this.$store.state.auth.permissions['people.createOwn'] && !this.$store.state.auth.permissions['people.createAny']) {
						this.person.seller_id = this.$store.state.auth.user.id;
					} else {
						this.person.seller_id = seller.id;
					}
				}
			},

			city: {
				get() {
					const city_id = _.get(this.person, 'city_id');
					return city_id ? this.cities.find((c) => c.id == city_id) : null;
				},

				set(city) {
					this.person.city_id = city.id;
				}
			}
		},

		mixins: [
			FetchingMixin
		],

		methods: {
            openPhoto(index) {
                this.$refs.pswp.openPhoto(index);
            },

            async excluiFoto(index) {
                try {
                    this.$delete(this.person.signature, index);

                } catch (error) {
                    console.log(error);
                }
            },

			getPeople(params = {}) {
				return this.$store.dispatch(`people/index`, params);
			},

			async sellerAsyncFind(keyword) {
				this.sellerFinding = true;

				try {
					let params = {"filter[people.seller]": true};

					if (keyword !== undefined)
						params["filter[people.name]"] = keyword;

					let response = await this.getPeople(params);
					this.sellers = response.data.data;

				} catch (e) {
					await exibeErro(e);
				}

				this.sellerFinding = false;
			},

			salvar() {
				const vm = this;
				vm.saving = true;

                vueSet(this.person, 'meta', this.personMeta);

                //vm.person.signature = JSON.stringify(Object.values(vm.person.signature));

				if (this.canSelectUser)
					this.person.roles = this.rolesBrute.map(function(item) {
						return item.name;
					});

				switch (this.operation) {
					case 'create':
						this.$store.dispatch(`people/post`, sanitize(vm.person))
							.then(function() {
								Swal.fire(
									'Sucesso!',
									'Pessoa criada com sucesso!',
									'success'
								).then(function(result) {
									if (result.isConfirmed)
										window.location = '/people';
								});
							})
							.catch(function (error) {
								// handle error
								exibeErro(error);
							})
							.then(function () {
								// always executed
								vm.saving = false;
							});
						break;

					case 'edit':
						this.$store.dispatch(`people/patch`, {
							id: vm.person.id,
							data: sanitize(this.person)
						})
							.then(function() {
								Swal.fire(
									'Sucesso!',
									'Pessoa atualizada com sucesso!',
									'success'
								);
							})
							.catch(function (error) {
								// handle error
								exibeErro(error);
							})
							.then(function () {
								// always executed
								vm.saving = false;
							});
						break;
				}
			},

			addPhone() {
				if (!this.person.phones)
					this.person.phones = [];

				this.person.phones.push('');
			},

			removePhone(index) {
				this.$delete(this.person.phones, index);
			},

			addRole() {
				this.rolesBrute.push('');
			},

			removeRole(index) {
				this.$delete(this.rolesBrute, index);
			},

			cityAsyncFind(keyword) {
				const vm = this;
				this.cityFinding = true;

				this.$store.dispatch(`cities/index`, {"filter[cities.name]": keyword})
					.then(function (response) {
						vm.cities = response.data.data;
					})
					.catch(function (error) {
						// handle error
						exibeErro(error);
					})
					.then(function () {
						// always executed
						vm.cityFinding = false;
					});
			},

			cityCustomLabel({ name, state }) {
				return `${name}/${state.acronym}`
			},
		},

		mounted() {
			this.operation = this.$route.name === 'people.edit' ? 'edit' : 'create';

			const vm = this;

			if (this.operation === 'edit') {
				this.loadingPerson = true;
				this.$store.dispatch(`people/get`, vm.$route.params.id)
					.then(function (response) {
						vm.rolesBrute = response.data.roles ?? [];
						vm.person = Object.assign(vm.person, response.data);

                        vueSet(vm, 'personMeta', Object.assign({}, vm.personMeta, response.data.meta));

						const city = _.get(vm.person,'city');
						if (city) vm.cities.push(vm.person.city);
					})
					.catch(function (error) {
						// handle error
						exibeErro(error);
					})
					.then(function () {
						// always executed
						vm.loadingPerson = false;
					});
			}

			if (this.personAnyPermission) {
				this.loadingSellers = true;
				this.$store.dispatch(`people/index`, {"filter[people.seller]": true, sort:'people.name', per_page: 9999})
					.then(function (response) {
						vm.sellers = response.data.data;
					})
					.catch(function (error) {
						// handle error
						exibeErro(error);
					})
					.then(function () {
						// always executed
						vm.loadingSellers = false;
					});
			}

			if (this.canSelectUser) {
				this.loadingRoles = true;
				this.$store.dispatch(`roles/index`, {sort:'roles.name', per_page: 9999})
					.then(function (response) {
						vm.roles = response.data.data;
					})
					.catch(function (error) {
						// handle error
						exibeErro(error);
					})
					.then(function () {
						// always executed
						vm.loadingRoles = false;
					});
			}

            vm.regionsFinding = true;
            this.$store.dispatch(`states/index`, {per_page: 26})
                .then(function (response) {
                    vm.states = response.data.data;
                })
                .catch(function (error) {
                    // handle error
                    exibeErro(error);
                })
                .then(function () {
                    // always executed
                    vm.regionsFinding = false;
                });
		}
	}
</script>

<style scoped>
	/* Large devices (desktops, 992px and up) */
	@media (min-width: 992px) {
		.content {
			padding: 10px 20px 20px 20px !important;
		}
	}
</style>
